<template>
  <van-tabbar route active-color="#3d6561">
    <van-tabbar-item icon="wap-home" to="/720cam/">首页</van-tabbar-item>
    <van-tabbar-item icon="column" to="/720cam/list">设备</van-tabbar-item>
    <van-tabbar-item icon="volume" to="/720cam/history">历史</van-tabbar-item>
    <van-tabbar-item icon="setting" to="/720cam/about">关于</van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: 'tabber',
}
</script>
